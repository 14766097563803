import { useQuery } from 'react-query';

import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { ShowQueryErrorType, ShowQueryOpts } from '../useShowQuery';
import { useShowQueryUpdateItemCache } from '../useShowQuery/hooks/useShowQueryUpdateItemCache';

import {
  FetchCacheItemSources,
  FetchItemCacheKey,
  FetchItemGqlQuery,
  NanoID,
  UUID
} from '../../../../../../types';
import { PermissionAction } from '../../../../../permissions/permissionsTypes';

import { fetchCacheItem } from '../baseActions/fetchCacheItem';
import { fetchReadItem } from '../baseActions/fetchReadItem';

import { LocalForage } from '../../../../../../utils/LocalForage';
import { parseRequestError } from '../../../../../../utils/parseRequestError';

import { CommonPermissions } from '../../../../commonConstants';

interface CacheShowQueryOptions<ResponseType> {
  action: PermissionAction;
  cacheKey: FetchItemCacheKey;
  cacheQuery?: FetchItemGqlQuery;
  itemKey: string;
  options: ShowQueryOpts<ResponseType>;
  placeholderData?: ResponseType;
  query: FetchItemGqlQuery;
  source?: FetchCacheItemSources;
  uuid: UUID | NanoID;
}

function useCacheShowQuery<ResponseType, ItemType>({
  action,
  cacheKey,
  query,
  cacheQuery,
  itemKey,
  options = {},
  placeholderData,
  source,
  uuid
}: CacheShowQueryOptions<ResponseType>) {
  const currentUser = useCurrentUser();
  const localForageCacheKey = `${cacheKey}-${uuid}`;
  const withCache =
    currentUser?.hasPermissions(action) &&
    currentUser.hasPermissions(CommonPermissions.READ_CACHE_QUERY);

  const {
    data: localForagePlaceholderData,
    isFetched: placeholderDataFetched
  } = useQuery<ResponseType | null>(
    `${localForageCacheKey}-placeholder`,
    () => LocalForage.getItem<ResponseType>(localForageCacheKey),
    {
      enabled: options.enabledPlaceholder,
      onSuccess: (data) => options.onSuccess?.(data)
    }
  );

  const {
    data,
    isFetched,
    isLoading,
    isFetching,
    error,
    isPlaceholderData,
    refetch
  } = useQuery<ResponseType, ShowQueryErrorType>(
    [cacheKey, uuid],
    () =>
      withCache
        ? fetchCacheItem({
            query: cacheQuery,
            uuid,
            source
          })
        : fetchReadItem({
            query,
            uuid
          }),
    {
      cacheTime: options.cacheTime,
      staleTime: options.staleTime,
      enabled:
        options.enabled ||
        (options.enabledPlaceholder && placeholderDataFetched),
      placeholderData:
        placeholderData || localForagePlaceholderData || undefined,
      onSettled: options.onSettled,
      onSuccess: (data) => {
        options.onSuccess?.(data);
        return LocalForage.setItem<ResponseType>(localForageCacheKey, data);
      },
      onError: options.onError
    }
  );

  const updateCacheShowQueryItemCache = useShowQueryUpdateItemCache<
    ResponseType,
    ItemType
  >({
    fullCacheKey: [cacheKey, uuid],
    itemKey
  });

  const item: ItemType | null = data?.[itemKey] || null;

  return {
    item,
    itemError: parseRequestError(error),
    itemErrorData: error,
    itemFetched: isFetched,
    itemFetching: isFetching,
    itemIsPlaceholderData: isPlaceholderData,
    itemLoading: isLoading,
    refetchItem: refetch,
    updateCacheShowQueryItemCache
  };
}

export default useCacheShowQuery;
