import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ProductsIndexTableRowOpenLightboxButtonProduct } from './ProductsIndexTableRowOpenLightboxButton.types';
import { OnLightboxOpenAction } from '../../../../../../../helpers/LightboxWrapper';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

import { Files } from '../../../../../../../utils/Files';

import { words } from '../../../../../../../locales/keys';

interface ProductsIndexTableRowOpenLightboxButtonProps {
  product: ProductsIndexTableRowOpenLightboxButtonProduct;
  onLightboxOpen: OnLightboxOpenAction;
}

function ProductsIndexTableRowOpenLightboxButton({
  product,
  onLightboxOpen
}: ProductsIndexTableRowOpenLightboxButtonProps) {
  const handleOpenLightbox = useCallback(() => {
    if (product.image) {
      onLightboxOpen(product);
    }
  }, [product, onLightboxOpen]);

  const hasImage = product.image?.file && Files.isImage(product.image.file);

  if (!hasImage) {
    return null;
  }

  return (
    <PureIconButtonHelper
      dataGa="products-product-open-lightbox-button"
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      icon={IconsEnum.ARROWS_EXPAND_SOLID}
      iconClassName="h-5 w-5 mr-2"
      i18nText={words.expand_view}
      onClick={handleOpenLightbox}
    />
  );
}

export default ProductsIndexTableRowOpenLightboxButton;
