import React from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { GADataType } from '../../../../../../../types';

import { useProductsListItemUpdateImageVersions } from './hooks/useProductsListItemUpdateImageVersions';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { Files } from '../../../../../../../utils/Files';

import { words } from '../../../../../../../locales/keys';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import { ProductsListItemUpdateImageVersionsProduct } from './ProductsListItemUpdateImageVersions.types';

interface ProductsListItemUpdateImageVersionsProps {
  product: ProductsListItemUpdateImageVersionsProduct;
}

function ProductsListItemUpdateImageVersions({
  dataGa,
  product
}: ProductsListItemUpdateImageVersionsProps & GADataType) {
  const hasImage = product.image?.file && Files.isImage(product.image.file);

  const { updateImageVersions, updateImageVersionsLoading } =
    useProductsListItemUpdateImageVersions({ product });

  if (!hasImage) {
    return null;
  }

  return (
    <PureTooltipIconButtonHelper
      className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
      dataGa={dataGa}
      icon={IconsEnum.PHOTOGRAPH_OUTLINE}
      iconClassName="h-5 w-5 stroke-1.75"
      tooltipSingleton
      tooltipI18nText={words.updateImageVersions}
      tooltipPlacement={TooltipPlacement.LEFT}
      disabled={updateImageVersionsLoading}
      onClick={updateImageVersions}
    />
  );
}

export default ProductsListItemUpdateImageVersions;
