import React, { memo, useCallback } from 'react';

import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';

import {
  FetchProductsSetsCacheKeys,
  UpdateProductsSetCacheAction
} from '../../../../productsSets/productsSetsTypes';
import { ClassName } from '../../../../../types';
import {
  ChangeProductsFiltersFunc,
  FetchProductsCacheKeys,
  FetchProductsFilters,
  OnProductAiSearchAction,
  OnProductSimilarSearchAction,
  OnSelectedProductsSidebarCloseAction,
  OnSelectedProductsSidebarOpenAction,
  ProductNanoID,
  UpdateProductCacheAction
} from '../../../productsTypes';
import {
  ProductsListItemOnProductsSelect,
  ProductsListItemProduct,
  ProductsListItemProductsSet
} from './ProductsListItem.types';
import { TeamNanoID } from '../../../../teams/teamsTypes';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useProductSelectButton } from '../../buttons/ProductSelectButton/hooks/useProductSelectButton';

import { DownloadProductButton } from '../../buttons/DownloadProductButton';
import { ProductFavoriteButton } from '../../buttons/ProductFavoriteButton';
import { ProductSelectButton } from '../../buttons/ProductSelectButton';
import { ProductsCopyLinkButton } from '../../buttons/ProductsCopyLinkButton';
import { ProductsListItemAiSearchButton } from './components/ProductsListItemAiSearchButton';
import { ProductsListItemSimilarSearchButton } from './components/ProductsListItemSimilarSearchButton';
import { ProductsListItemBrandNameFilterButton } from './components/ProductsListItemBrandNameFilterButton';
import { ProductsListItemEditLink } from './components/ProductsListItemEditLink';
import { ProductsListItemNda } from './components/ProductsListItemNda';
import { ProductsListItemOpenLightboxButton } from './components/ProductsListItemOpenLightboxButton';
import { ProductsListItemPreview } from './components/ProductsListItemPreview';
import { ProductsListItemScore } from './components/ProductsListItemScore';
import { ProductsListItemSku } from './components/ProductsListItemSku';
import { ProductsListItemTags } from './components/ProductsListItemTags';
import { ProductsListItemUploaded } from './components/ProductsListItemUploaded';
import { ProductsListItemUpdateImageVersions } from './components/ProductsListItemUpdateImageVersions';
import { SubmitProductToProjectOrTaskModalButton } from '../../buttons/SubmitProductToProjectOrTaskModalButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { NextPureTooltipIconLinkHelper } from '../../../../../helpers/links/NextPureTooltipIconLinkHelper';
import { ProductModelViewerModalButton } from '../../../helpers/ProductModelViewerModalButton';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';
import { TooltipSingletonSourceWrapper } from '../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';

import { ProductsPermissions } from '../../../productsConstants';

import { ProductPath } from '../../../ProductPath';
import { TeamPath } from '../../../../teams/TeamPath';

import { words } from '../../../../../locales/keys';

interface ProductsListItemDefaultProps {
  className?: ClassName;
  companyNanoId?: TeamNanoID;
  isMyLibrary?: boolean;
  product: ProductsListItemProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsFilters?: FetchProductsFilters;
  changeProductsFilters?: ChangeProductsFiltersFunc;
  onLightboxOpen: OnLightboxOpenAction;
  updateProductCache?: UpdateProductCacheAction<ProductsListItemProduct>;
  onProductEditButtonMouseEnter?: (productNanoId: ProductNanoID) => void;
  onProductAiSearch?: OnProductAiSearchAction;
  onProductSimilarSearch?: OnProductSimilarSearchAction;
  onProductSimilarSearchFilter?: OnProductSimilarSearchAction;
  withProductPreviewLink?: boolean;
}

interface ProductsListItemWithSelectProps {
  productsSet: ProductsListItemProductsSet | null;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  onSelectedProductsSidebarOpen: OnSelectedProductsSidebarOpenAction;
  onSelectedProductsSidebarClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<ProductsListItemProductsSet>;
  onProductsSelect?: ProductsListItemOnProductsSelect;
}

interface ProductsListItemWithoutSelectProps {
  productsSet?: never;
  productsSetCacheKeys?: never;
  onSelectedProductsSidebarOpen?: never;
  onSelectedProductsSidebarClose?: never;
  updateProductsSetCache?: never;
  onProductsSelect?: never;
}

type ProductsListItemProps = ProductsListItemDefaultProps &
  (ProductsListItemWithSelectProps | ProductsListItemWithoutSelectProps);

function ProductsListItem({
  className,
  companyNanoId,
  isMyLibrary,
  product,
  productsSet,
  productsCacheKeys,
  productsSetCacheKeys,
  productsFilters,
  changeProductsFilters,
  onLightboxOpen,
  onSelectedProductsSidebarOpen,
  onSelectedProductsSidebarClose,
  updateProductsSetCache,
  updateProductCache,
  onProductsSelect,
  onProductEditButtonMouseEnter,
  onProductAiSearch,
  onProductSimilarSearch,
  onProductSimilarSearchFilter,
  withProductPreviewLink
}: ProductsListItemProps) {
  const { isSelected, handleToggleSelected } = useProductSelectButton({
    product,
    productsSet,
    onSelectedProductsSidebarOpen,
    onSelectedProductsSidebarClose,
    updateProductsSetCache,
    onSelect: onProductsSelect,
    productsSetCacheKeys
  });

  const handleOpenLightbox = useCallback(() => {
    if (product.image) {
      onLightboxOpen(product);
    }
  }, [product, onLightboxOpen]);

  const handleEditButtonMouseEnter = useCallback<() => void>(() => {
    onProductEditButtonMouseEnter?.(product.nanoId);
  }, [onProductEditButtonMouseEnter, product.nanoId]);

  let downloadButtonAction = ProductsPermissions.READ_PRODUCT_DOWNLOAD_BUTTON;
  let productPagePath = ProductPath.show(product.nanoId);

  if (isMyLibrary) {
    productPagePath = TeamPath.currentCompanyLibraryProduct(product.nanoId);
    downloadButtonAction =
      ProductsPermissions.READ_SELF_COMPANY_LIBRARY_PRODUCT_DOWNLOAD_BUTTON;
  } else if (companyNanoId) {
    productPagePath = TeamPath.companyLibraryProduct(
      companyNanoId,
      product.nanoId
    );
    downloadButtonAction =
      ProductsPermissions.READ_OTHER_COMPANY_LIBRARY_PRODUCT_DOWNLOAD_BUTTON;
  }

  return (
    <div
      className={
        className || 'w-120 max-w-full flex flex-col relative rounded-md group'
      }
    >
      <ProductsListItemPreview
        product={product}
        productPagePath={productPagePath}
        onLightboxOpen={withProductPreviewLink ? undefined : handleOpenLightbox}
      />

      <div className="text-sm p-3 flex-1 flex flex-col">
        <div className="flex-1">
          <div className="font-medium truncate">
            <NextPureTooltipIconLinkHelper
              dataGa="products-product-name-link"
              href={productPagePath}
              text={product.name}
              tooltipI18nText={product.name}
              tooltipPlacement={TooltipPlacement.BOTTOM}
            />
          </div>

          <div className="text-gray-600 dark:text-gray-500 truncate">
            <ProductsListItemNda product={product} />
            <ProductsListItemBrandNameFilterButton
              dataGa="products-product-brand-name-filter-button"
              product={product}
              productsFilters={productsFilters}
              changeProductsFilters={changeProductsFilters}
            />
          </div>

          <ProductsListItemSku product={product} />

          <CheckPermissions action={ProductsPermissions.READ_PRODUCT_TAGS}>
            <ProductsListItemTags product={product} />
          </CheckPermissions>

          <CheckPermissions action={ProductsPermissions.READ_PRODUCT_SCORE}>
            <ProductsListItemScore product={product} />
          </CheckPermissions>

          <CheckPermissions action={ProductsPermissions.READ_PRODUCT_UPLOADED}>
            <ProductsListItemUploaded product={product} />
          </CheckPermissions>
        </div>

        <div className="mt-3 flex justify-between items-center relative z-5">
          <div className="flex items-center gap-2">
            {onSelectedProductsSidebarOpen ? (
              <ProductSelectButton
                dataGa="products-product-select-button"
                handleToggleSelected={handleToggleSelected}
                isSelected={isSelected}
                selectedClassName="border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm text-white bg-blue-500 hover:bg-blue-600 border-transparent space-x-1"
                unselectedClassName="border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 text-gray-700 hover:bg-gray-50"
              />
            ) : (
              <CheckPermissions action={downloadButtonAction}>
                <DownloadProductButton
                  className="border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 text-gray-700 hover:bg-gray-50"
                  dataGa="products-product-download-button"
                  i18nText={words.download}
                  productUuid={product.uuid}
                  tooltipSingleton
                />
              </CheckPermissions>
            )}

            <CheckPermissions
              action={ProductsPermissions.READ_PRODUCT_PREVIEW_MODEL_BUTTON}
            >
              {product.previewModel && (
                <ProductModelViewerModalButton
                  className="py-1 pl-1 pr-1 rounded-md whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border-gray-300 dark:border-gray-700 text-gray-700 dark:text-gray-300 bg-yellow-50 dark:bg-yellow-900 dark:bg-opacity-50 border hover:shadow inline-flex gap-1 items-center relative shadow-sm"
                  dataGa="products-product-view-3D-model-button"
                  title={`${product?.name}, ${product?.brand?.localizedName}`}
                  icon={IconsEnum.CUBE_TRANSPARENT_SOLID}
                  iconClassName="h-5 w-5"
                  tooltipI18nText={words.view3DModel}
                  tooltipPlacement={TooltipPlacement.TOP}
                  previewModel={product.previewModel}
                />
              )}
            </CheckPermissions>
          </div>

          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCT_FAVORITE_BUTTON}
          >
            <ProductFavoriteButton
              className={
                product.favorite
                  ? 'focus:ring-offset-0 items-center rounded-full text-pink-600 hover:text-pink-500 flex p-0.5'
                  : 'focus:ring-offset-0 items-center rounded-full text-gray-400 dark:text-gray-500 hover:text-pink-600 dark:hover:text-pink-600 flex p-1'
              }
              dataGa="products-product-favorite-button"
              icon={
                product.favorite
                  ? IconsEnum.HEART_SOLID
                  : IconsEnum.HEART_OUTLINE
              }
              product={product}
              productsCacheKeys={productsCacheKeys}
              productsSetCacheKeys={productsSetCacheKeys}
              updateIndexProductCache={updateProductCache}
            />
          </CheckPermissions>
        </div>
      </div>

      {isSelected ? (
        <div className="absolute inset-0 rounded-md ring-4 ring-blue-500 bg-blue-500 opacity-10 pointer-events-none" />
      ) : null}

      {product.blocked ? (
        <div className="absolute inset-0 rounded-md bg-crossed bg-red-200 bg-opacity-10 pointer-events-none" />
      ) : null}

      <div className="absolute inset-0 pointer-events-none flex sm:opacity-0 group-hover:opacity-100 focus-within:opacity-100 items-start justify-end">
        <div className="flex flex-col space-y-1 pointer-events-auto p-2">
          <TooltipSingletonSourceWrapper
            placement={TooltipPlacement.LEFT}
            withArrow
          >
            {onProductSimilarSearchFilter && (
              <CheckPermissions
                action={
                  ProductsPermissions.READ_PRODUCT_FIND_SIMILAR_FILTER_BUTTON
                }
              >
                <ProductsListItemSimilarSearchButton
                  dataGa="products-product-similar-search-filter-button"
                  onProductSimilarSearch={onProductSimilarSearchFilter}
                  product={product}
                />
              </CheckPermissions>
            )}

            {onProductSimilarSearch && (
              <CheckPermissions
                action={ProductsPermissions.READ_PRODUCT_FIND_SIMILAR_BUTTON}
              >
                <ProductsListItemSimilarSearchButton
                  dataGa="products-product-similar-search-button"
                  onProductSimilarSearch={onProductSimilarSearch}
                  product={product}
                />
              </CheckPermissions>
            )}

            {onProductAiSearch && (
              <CheckPermissions
                action={
                  ProductsPermissions.READ_PRODUCT_AI_SEARCH_FIND_ALTERNATIVES_BUTTON
                }
              >
                <ProductsListItemAiSearchButton
                  dataGa="products-product-ai-search-button"
                  onProductAiSearch={onProductAiSearch}
                  product={product}
                />
              </CheckPermissions>
            )}

            <CheckPermissions action={downloadButtonAction}>
              <DownloadProductButton
                className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
                dataGa="products-product-download-button"
                icon={IconsEnum.DOWNLOAD_SOLID}
                iconClassName="h-5 w-5"
                productUuid={product.uuid}
                tooltipI18nText={words.download}
                tooltipSingleton
              />
            </CheckPermissions>

            <CheckPermissions
              action={
                ProductsPermissions.READ_PRODUCT_SUBMIT_TO_TASK_OR_PROJECT_BUTTON
              }
            >
              <SubmitProductToProjectOrTaskModalButton
                dataGa="products-product-submit-to-task-or-project-button"
                product={product}
              />
            </CheckPermissions>

            <ProductsListItemOpenLightboxButton
              dataGa="products-product-open-lightbox-button"
              product={product}
              onLightboxOpen={handleOpenLightbox}
            />

            <CheckPermissions
              action={
                ProductsPermissions.READ_PRODUCT_UPDATE_IMAGE_VERSION_BUTTON
              }
            >
              <ProductsListItemUpdateImageVersions
                dataGa="products-product-update-image-versions-button"
                product={product}
              />
            </CheckPermissions>

            <CheckPermissions
              action={ProductsPermissions.READ_PRODUCT_COPY_LINK_BUTTON}
            >
              <ProductsCopyLinkButton
                className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
                dataGa="products-product-copy-link-button"
                icon={IconsEnum.LINK_SOLID}
                iconClassName="h-5 w-5 stroke-1.75"
                product={product}
                tooltipI18nText={words.copyLink}
              />
            </CheckPermissions>

            <CheckPermissions
              action={ProductsPermissions.READ_PRODUCT_EDIT_BUTTON}
            >
              <ProductsListItemEditLink
                dataGa="products-product-edit-link"
                product={product}
                onMouseEnter={handleEditButtonMouseEnter}
              />
            </CheckPermissions>
          </TooltipSingletonSourceWrapper>
        </div>
      </div>
    </div>
  );
}

export default memo<ProductsListItemProps>(ProductsListItem);
