import React from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ProductsIndexTableRowUpdateImageVersionsProduct } from './ProductsIndexTableRowUpdateImageVersions.types';

import { useProductsIndexTableRowUpdateImageVersions } from './hooks/useProductsIndexTableRowUpdateImageVersions';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

import { Files } from '../../../../../../../utils/Files';

import { words } from '../../../../../../../locales/keys';

interface ProductsIndexTableRowUpdateImageVersionsProps {
  product: ProductsIndexTableRowUpdateImageVersionsProduct;
}

function ProductsIndexTableRowUpdateImageVersions({
  product
}: ProductsIndexTableRowUpdateImageVersionsProps) {
  const hasImage = product.image?.file && Files.isImage(product.image.file);

  const { updateImageVersions, updateImageVersionsLoading } =
    useProductsIndexTableRowUpdateImageVersions({ product });

  if (!hasImage) {
    return null;
  }

  return (
    <PureIconButtonHelper
      dataGa="products-product-update-image-versions-button"
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      icon={IconsEnum.PHOTOGRAPH_OUTLINE}
      iconClassName="h-5 w-5 stroke-1.75 mr-2"
      i18nText={words.updateImageVersions}
      disabled={updateImageVersionsLoading}
      onClick={updateImageVersions}
    />
  );
}

export default ProductsIndexTableRowUpdateImageVersions;
