import React from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName, GADataType, I18nText } from '../../../../../types';

import {
  FetchProductsCacheKeys,
  UpdateProductCacheAction,
  UpdateShowProductCacheAction
} from '../../../productsTypes';

import { FetchProductsSetsCacheKeys } from '../../../../productsSets/productsSetsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useProductFavoriteButton from './useProductFavoriteButton';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { ProductFavoriteButtonProduct } from './ProductFavoriteButton.types';

import { words } from '../../../../../locales/keys';

interface ProductFavoriteButtonProps {
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  product: ProductFavoriteButtonProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  updateIndexProductCache?: UpdateProductCacheAction<ProductFavoriteButtonProduct>;
  updateShowProductCache?: UpdateShowProductCacheAction<ProductFavoriteButtonProduct>;
}

function ProductFavoriteButton({
  className,
  dataGa,
  i18nText,
  icon,
  iconClassName,
  product,
  productsCacheKeys,
  productsSetCacheKeys,
  updateIndexProductCache,
  updateShowProductCache
}: ProductFavoriteButtonProps & GADataType) {
  const { handleToggleFavorite, toggleProductFavoriteLoading } =
    useProductFavoriteButton({
      product,
      productsCacheKeys,
      productsSetCacheKeys,
      updateIndexProductCache,
      updateShowProductCache
    });

  return (
    <PureTooltipIconButtonHelper
      className={className}
      dataGa={dataGa}
      disabled={toggleProductFavoriteLoading}
      i18nText={i18nText}
      icon={icon}
      iconClassName={iconClassName}
      onClick={handleToggleFavorite}
      tooltipI18nText={
        product.favorite ? words.removeFromFavorites : words.addToFavorites
      }
      tooltipPlacement={TooltipPlacement.TOP}
    />
  );
}

export default ProductFavoriteButton;
