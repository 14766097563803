import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKeys
} from '../../../../../../productsSets/productsSetsTypes';

import {
  OnSelectedProductsSidebarOpenAction,
  OnSelectedProductsSidebarCloseAction
} from '../../../../../productsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import useProductsListItemLightboxSelectButton from './useProductsListItemLightboxSelectButton';

import { words } from '../../../../../../../locales/keys';

import { TooltipPlacement } from '../../../../../../../helpers/tooltips/tooltipsConstants';

import {
  ProductsListItemLightboxSelectButtonProduct,
  ProductsListItemLightboxSelectButtonProductsSet
} from './ProductsListItemLightboxSelectButton.types';

interface ProductsListItemLightboxSelectButtonProps {
  product: ProductsListItemLightboxSelectButtonProduct;
  productsSet: ProductsListItemLightboxSelectButtonProductsSet | null;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  onSelectedProductsSidebarOpen: OnSelectedProductsSidebarOpenAction;
  onSelectedProductsSidebarClose: OnSelectedProductsSidebarCloseAction;
  updateProductsSetCache: UpdateProductsSetCacheAction<ProductsListItemLightboxSelectButtonProductsSet>;
}

function ProductsListItemLightboxSelectButton({
  product,
  productsSet,
  productsSetCacheKeys,
  onSelectedProductsSidebarOpen,
  onSelectedProductsSidebarClose,
  updateProductsSetCache
}: ProductsListItemLightboxSelectButtonProps) {
  const { isSelected, handleToggleSelected } =
    useProductsListItemLightboxSelectButton({
      product,
      productsSet,
      productsSetCacheKeys,
      onSelectedProductsSidebarOpen,
      onSelectedProductsSidebarClose,
      updateProductsSetCache
    });

  return (
    <PureTooltipIconButtonHelper
      className={cl(
        'py-2 font-medium inline-flex space-x-1 items-center rounded-md',
        isSelected
          ? 'pl-2 pr-4 whitespace-nowrap text-sm leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 text-white bg-blue-600 hover:bg-blue-500 shadow-sm hover:shadow-md focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 focus:ring-offset-2'
          : 'text-gray-800 hover:bg-gray-300 bg-gray-200 px-4 focus:ring-white focus:ring-offset-black'
      )}
      dataGa="product-lightbox-select-button"
      i18nText={isSelected ? words.selected : words.select}
      icon={isSelected ? IconsEnum.CHECK : null}
      iconClassName="h-6 w-6 p-0.5"
      tooltipSingleton
      tooltipI18nText={words.selectToAddToTheProject}
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleToggleSelected}
    />
  );
}

export default ProductsListItemLightboxSelectButton;
