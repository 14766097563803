import { useCallback } from 'react';

import {
  FetchProductsCacheKeys,
  UpdateProductCacheAction,
  UpdateShowProductCacheAction
} from '../../../productsTypes';
import { ProductFavoriteButtonProduct } from './ProductFavoriteButton.types';
import { FetchProductsSetsCacheKeys } from '../../../../productsSets/productsSetsTypes';

import {
  TOGGLE_PRODUCT_FAVORITE_QUERY,
  ToggleProductFavoriteQueryResponse
} from '../../../queries/toggleProductFavorite.query';

import { useToggleProductFavorite } from '../../../hooks/useToggleProductFavorite';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

interface useProductFavoriteButtonProps {
  product: ProductFavoriteButtonProduct;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  updateIndexProductCache?: UpdateProductCacheAction<ProductFavoriteButtonProduct>;
  updateShowProductCache?: UpdateShowProductCacheAction<ProductFavoriteButtonProduct>;
}

function useProductFavoriteButton({
  product,
  productsCacheKeys,
  productsSetCacheKeys,
  updateIndexProductCache,
  updateShowProductCache
}: useProductFavoriteButtonProps) {
  const {
    toggleProductFavorite,
    toggleProductFavoriteErrorMessage,
    toggleProductFavoriteLoading
  } = useToggleProductFavorite<ToggleProductFavoriteQueryResponse>({
    query: TOGGLE_PRODUCT_FAVORITE_QUERY,
    // Disable immediate invalidateQueries (use optimistic update instead)
    // cacheKeys: compact([
    //   ProductCache.indexCacheKey(),
    //   ProductsSetCache.showCacheKey(),
    //   ...(productsCacheKeys || []),
    //   ...(productsSetCacheKeys || [])
    // ]),
    updateIndexProductCache,
    updateShowProductCache
  });

  const handleToggleFavorite = useCallback<() => void>(
    () => toggleProductFavorite({ uuid: product.uuid }),
    [product, toggleProductFavorite]
  );

  useShowToastOnErrorChange({ error: toggleProductFavoriteErrorMessage });

  return {
    handleToggleFavorite,
    toggleProductFavoriteLoading
  };
}

export default useProductFavoriteButton;
