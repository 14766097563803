import React, { Fragment } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { ProductsIndexTableRowDropdownContentProduct } from './ProductsIndexTableRowDropdownContent.types';
import {
  OnProductAiSearchAction,
  OnProductSimilarSearchAction,
  ProductNanoID
} from '../../../../../productsTypes';
import { OnLightboxOpenAction } from '../../../../../../../helpers/LightboxWrapper';

import { ProductsIndexTableRowAiSearchButton } from '../ProductsIndexTableRowAiSearchButton';
import { ProductsIndexTableRowUpdateImageVersions } from '../ProductsIndexTableRowUpdateImageVersions';
import { ProductsIndexTableRowOpenLightboxButton } from '../ProductsIndexTableRowOpenLightboxButton';
import { ProductsIndexTableRowEditLink } from '../ProductsIndexTableRowEditLink';
import { ProductsIndexTableRowSimilarSearchButton } from '../ProductsIndexTableRowSimilarSearchButton';

import { DownloadProductButton } from '../../../../buttons/DownloadProductButton';
import { SubmitProductToProjectOrTaskModalButton } from '../../../../buttons/SubmitProductToProjectOrTaskModalButton';
import { ProductsCopyLinkButton } from '../../../../buttons/ProductsCopyLinkButton';
import { ProductModelViewerModalButton } from '../../../../../helpers/ProductModelViewerModalButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { words } from '../../../../../../../locales/keys';
import { ProductsPermissions } from '../../../../../productsConstants';

interface ProductsIndexTableRowDropdownContentProps {
  onLightboxOpen: OnLightboxOpenAction;
  onProductAiSearch?: OnProductAiSearchAction;
  onProductEditButtonMouseEnter?: (productNanoId: ProductNanoID) => void;
  onProductSimilarSearch?: OnProductSimilarSearchAction;
  onProductSimilarSearchFilter?: OnProductSimilarSearchAction;
  product: ProductsIndexTableRowDropdownContentProduct;
  downloadButtonAction: ProductsPermissions;
}

function ProductsIndexTableRowDropdownContent({
  onLightboxOpen,
  onProductAiSearch,
  onProductEditButtonMouseEnter,
  onProductSimilarSearch,
  onProductSimilarSearchFilter,
  product,
  downloadButtonAction
}: ProductsIndexTableRowDropdownContentProps) {
  return (
    <Fragment>
      {onProductSimilarSearchFilter && (
        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCT_FIND_SIMILAR_FILTER_BUTTON}
        >
          <ProductsIndexTableRowSimilarSearchButton
            dataGa="products-product-similar-search-filter"
            onProductSimilarSearch={onProductSimilarSearchFilter}
            product={product}
          />
        </CheckPermissions>
      )}

      {onProductSimilarSearch && (
        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCT_FIND_SIMILAR_BUTTON}
        >
          <ProductsIndexTableRowSimilarSearchButton
            dataGa="products-product-similar-search-button"
            onProductSimilarSearch={onProductSimilarSearch}
            product={product}
          />
        </CheckPermissions>
      )}

      {onProductAiSearch && (
        <CheckPermissions
          action={
            ProductsPermissions.READ_PRODUCT_AI_SEARCH_FIND_ALTERNATIVES_BUTTON
          }
        >
          <ProductsIndexTableRowAiSearchButton
            dataGa="products-product-ai-search-button"
            product={product}
            onProductAiSearch={onProductAiSearch}
          />
        </CheckPermissions>
      )}

      <CheckPermissions action={downloadButtonAction}>
        <DownloadProductButton
          dataGa="products-product-download-button"
          productUuid={product.uuid}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          icon={IconsEnum.DOWNLOAD_SOLID}
          iconClassName="h-5 w-5 mr-2"
          i18nText={words.download}
        />
      </CheckPermissions>

      <CheckPermissions
        action={
          ProductsPermissions.READ_PRODUCT_SUBMIT_TO_TASK_OR_PROJECT_BUTTON
        }
      >
        <SubmitProductToProjectOrTaskModalButton
          dataGa="products-product-submit-to-project-or-task-button"
          product={product}
          i18nText={words.attach}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          iconClassName="h-5 w-5 stroke-1.75 mr-2"
        />
      </CheckPermissions>

      <ProductsIndexTableRowOpenLightboxButton
        product={product}
        onLightboxOpen={onLightboxOpen}
      />

      <CheckPermissions
        action={ProductsPermissions.READ_PRODUCT_UPDATE_IMAGE_VERSION_BUTTON}
      >
        <ProductsIndexTableRowUpdateImageVersions product={product} />
      </CheckPermissions>

      <CheckPermissions
        action={ProductsPermissions.READ_PRODUCT_COPY_LINK_BUTTON}
      >
        <ProductsCopyLinkButton
          dataGa="products-product-copy-link-button"
          product={product}
          className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
          icon={IconsEnum.LINK_SOLID}
          iconClassName="h-5 w-5 stroke-1.75 mr-2"
          i18nText={words.copyLink}
        />
      </CheckPermissions>

      <CheckPermissions
        action={ProductsPermissions.READ_PRODUCT_PREVIEW_MODEL_BUTTON}
      >
        {product.previewModel && (
          <ProductModelViewerModalButton
            className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
            dataGa="products-product-view-3d-model-button"
            title={`${product?.name}, ${product?.brand?.localizedName}`}
            icon={IconsEnum.CUBE_TRANSPARENT_SOLID}
            iconClassName="h-5 w-5 mr-2"
            i18nText={words.view3DModel}
            previewModel={product.previewModel}
          />
        )}
      </CheckPermissions>

      <CheckPermissions action={ProductsPermissions.READ_PRODUCT_EDIT_BUTTON}>
        <ProductsIndexTableRowEditLink
          dataGa="products-product-edit-button"
          product={product}
          onProductEditButtonMouseEnter={onProductEditButtonMouseEnter}
        />
      </CheckPermissions>
    </Fragment>
  );
}

export default ProductsIndexTableRowDropdownContent;
