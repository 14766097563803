import React from 'react';

import { ProductsIndexTableRowProductPreviewProduct } from './ProductsIndexTableRowProductPreview.types';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { Icon } from '../../../../../../../helpers/Icon';
import {
  ImageHelper,
  ImageItemImageVersions
} from '../../../../../../../helpers/ImageHelper';

import { Files } from '../../../../../../../utils/Files';

import { ButtonHelper } from '../../../../../../../helpers/buttons/ButtonHelper';

const srcSetVersions = {
  '1x': ImageItemImageVersions.MiniThumb320x320,
  '2x': ImageItemImageVersions.MiniThumb640x640,
  '3x': ImageItemImageVersions.MiniThumb960x960
};

interface ProductsIndexTableRowProductPreviewProps {
  product: ProductsIndexTableRowProductPreviewProduct;
  onLightboxOpen?: () => void;
}

function ProductsIndexTableRowProductPreview({
  product,
  onLightboxOpen
}: ProductsIndexTableRowProductPreviewProps) {
  return (
    <div className="relative rounded-md overflow-hidden aspect-w-1 aspect-h-1">
      {product.image?.file && Files.isImage(product.image?.file) ? (
        <ButtonHelper
          dataGa="products-product-lightbox-button"
          className="absolute inset-0 cursor-pointer"
          onClick={onLightboxOpen}
        >
          <ImageHelper
            className="h-full w-full object-cover"
            src={product.image.file}
            version={ImageItemImageVersions.MiniThumb320x320}
            srcSetVersions={srcSetVersions}
            alt={product.name}
          />
        </ButtonHelper>
      ) : (
        <div className="flex items-center justify-center text-gray-600 absolute inset-0">
          <Icon icon={IconsEnum.PHOTOGRAPH_OUTLINE} className="h-12 w-12" />
        </div>
      )}
    </div>
  );
}

export default ProductsIndexTableRowProductPreview;
