import React, { useCallback } from 'react';

import { GADataType } from '../../../../../../../types';
import { IconsEnum } from '../../../../../../../assets/icons/types';
import { OnProductSimilarSearchAction } from '../../../../../productsTypes';

import { ProductsAiSearchProduct } from '../../../../containers/ProductsAiSearch';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

import { aiSearchKeys } from '../../../../../../../locales/keys';

interface ProductsIndexTableRowSimilarSearchButtonProps {
  product: ProductsAiSearchProduct;
  onProductSimilarSearch?: OnProductSimilarSearchAction;
}

function ProductsIndexTableRowSimilarSearchButton({
  dataGa,
  product,
  onProductSimilarSearch
}: ProductsIndexTableRowSimilarSearchButtonProps & GADataType) {
  const handleProductSimilarSearch = useCallback<() => void>(() => {
    onProductSimilarSearch?.(product);
  }, [product, onProductSimilarSearch]);

  return (
    <PureIconButtonHelper
      className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
      dataGa={dataGa}
      i18nText={aiSearchKeys.findSimilar}
      icon={IconsEnum.PHOTO_SOLID}
      iconClassName="h-5 w-5 mr-2"
      onClick={handleProductSimilarSearch}
    />
  );
}

export default ProductsIndexTableRowSimilarSearchButton;
